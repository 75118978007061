/* Reset */
body {
  background-color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  margin: 0;
}

.perfect-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 1000px;
}

.column {
  margin: 0 1.5rem;
}
.horizontal_list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.socials_icon {
  height: 3rem;
  width: auto;
  margin: 0 .5rem;
}

.title {
  font-size: 3rem;
  font-weight: normal;
  margin: 0;
}

.subtitle {
  color: #3d4747;
  font-weight: normal;
  margin-top: 0;
  text-transform: uppercase;
}


/* Sticky Footer */
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1 0 auto;
}

header, footer {
  flex-shrink: 0;
}


/* Navigation List */
.top-navigation {
  background-color: #282829;
  box-shadow: 2px 2px 2px #28282926;
  color: white;
  margin-bottom: 1rem;
}

.nav_list {
  justify-content: flex-end;
}

.nav_item {
  position: relative;
}

.nav_link {
  color: inherit;
  cursor: pointer;
  display: flex;
  padding: 1rem 1.25rem;
  text-decoration: none;
}

.nav_link:hover {
  background-color: #c87745;
}

.nav_icon {
  margin-right: 0.25rem;
}

/* Dropdown Menu */
.nav_dropdown {
  background-color: #1a1a1b;
  border-right: 5px solid #c87745;
  display: none;
  padding: 0;
  position: absolute;
  top: 56px;
  right: 0;
  min-width: 150px;
}

.nav_item.has_dropdown:hover > .nav_dropdown {
  display: block;
}

.nav_dropdown .nav_link {
  width: 100%;
}